// import 'bulma/css/bulma.css'; I put Bulma directly
import './css/main.css';
import './css/tooltip.css';
import ClipboardJS from 'clipboard';
import { shuffle } from './utils/utils';
var TOOLTIP_TIMEOUT = 1500;
function init() {
    var burger = document.querySelector('.burger');
    var menu = document.querySelector('#' + burger.dataset.target);
    burger.addEventListener('click', function () {
        burger.classList.toggle('is-active');
        menu.classList.toggle('is-active');
    });
    new ClipboardJS('.hashtags__copy');
    var btns = document.querySelectorAll('.hashtags__copy');
    btns.forEach(function (btn) {
        btn.addEventListener('click', function (e) {
            var target = e.target;
            target.setAttribute('data-balloon-pos', 'up');
            target.setAttribute('data-balloon', '👌 Скопировано!');
            target.setAttribute('data-balloon-visible', '');
            setTimeout(function () {
                target.removeAttribute('data-balloon');
                target.removeAttribute('data-balloon-pos');
                target.removeAttribute('data-balloon-visible');
            }, TOOLTIP_TIMEOUT);
        });
    });
    var shfBtns = document.querySelectorAll('.hashtags__shuffle');
    shfBtns.forEach(function (btn) {
        var count = btn.dataset.count;
        btn.addEventListener('click', function (e) {
            var target = e.target, wrapper = target.parentElement, content = wrapper.querySelector('.content p');
            var tags = window.allTagsShuffled.slice();
            shuffle(tags);
            if (target.dataset.count) {
                tags = tags.slice(0, Number(target.dataset.count));
            }
            var text = tags.map(function (i) { return '#' + i; }).join(' ');
            content.textContent = text;
        });
    });
}
init();
